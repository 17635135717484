table {
    border:none;
    border-collapse: collapse;
}

table td {
    border-right: 1px solid rgb(170, 170, 170,0.3);
    width: 25vw;
    padding: 10px 30px;
    margin: auto;
    
}

table td:first-child {
    border-left: none;
}

table td:last-child {
    border-right: none;
}

table th{
    text-align: center;
}

.headert {
    text-align: left;
    background-color: $dark ;
    color: white;
    font-weight: bold;
  }
  
  .myDataGrid .MuiDataGrid-cell,
  .myDataGrid .MuiDataGrid-columnHeader {
    border-right: 1px solid rgba(224, 224, 224, 1);
  }
  
  .myDataGrid .MuiDataGrid-row:last-child .MuiDataGrid-cell {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

.custom-switch {
  transform: scale(1.3); /* Adjust the scale value as needed */
}
// .button{
//     text-align: center;
// }


.text-wrap {
  white-space: normal !important;
  word-wrap: break-word !important;
  line-height: 1.2em;
  margin: 5px 0;
}

.myDataGrid {
  max-height: 500px;
  overflow: auto;
}