.progress-line {
  height: 10px;
  width: 10px;
  background-color: $secondary;
  border-radius: 5px;
  margin: 10px;
}

.progress-selected-line {
  background-color: $primary !important;
}

.logo-icons {
  height: 40px;
  margin: 9px auto;
}

.logo-provider {
  height: 30px;
}

.wizard-title {
  font-size: 1.4rem;
  color: $primary;
}

.wizard-sub-title {
  color: $secondary;
}

.stepper-icon-active {
  color: $primary !important;
  /* Primary color */
}

.stepper-completed {
  color: $primary !important;
  /* Primary color */
}

.custom-list {
  counter-reset: item 6;
  /* Start counting from 6 */
}

.custom-list li::before {
  counter-increment: item;
  content: counter(item) ". ";
  /* Display the custom numbering */
}

.side-img {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  height: 100%;
  z-index: 999;
}