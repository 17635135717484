

.bullet-container {
    margin-left: 20px; /* Adjust the margin as needed */
  }
  
  .bullet-item {
    margin-bottom: 5px; /* Adjust the margin as needed */
  }
  
  .bullet-item::before {
    content: '\2022'; /* Unicode character for a bullet point */
    margin-right: 10px; /* Adjust the spacing between the bullet and text as needed */
  }
  
  .back-element {
    position: relative;
    z-index: 1 !important; /* Set a lower z-index value */
  }
  
  .front-element {
    position: relative;
    z-index: 200 !important; /* Set a higher z-index value */
  }
  
  .front-element-top {
    position: relative;
    z-index: 1000 !important; /* Set a higher z-index value */
    overflow: visible;
  }
  .full-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000 !important; /* Set a high z-index to make sure it appears above other content */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(0,0,0,0.7);
    margin: 50px;
    border-radius: 10px;
  }
  
  .overlay-content {
    width: 25vw;
    font-size: 1.4rem; /* Adjust text color for better visibility */
    text-align: center;
  }
  